import React, { useEffect, useMemo, useState } from "react";
import { Card, Elevation, H5, InputGroup } from "@blueprintjs/core";
import type { Workload } from "../../../../../@types/sd/workload";
import styles from "./DeploymentDetails.module.css";
import SdButton from "../../../../../components/theming/SdButton";
import Spacer from "../../../../../components/Util/Util";
import { useSaveSandboxSpec } from "../../../../CreateSandbox/CreateSandboxAction/CreateSandboxAction";
import type { SandboxV2 } from "../../../../../@types/sd/sandboxv2";
import EnvVarEditor from "./EnvVarEditor";
import type { ForkOf, SandboxFork } from "../../../../../@types/sd/sandbox";
import { forkCustomizationEnvToEnvVar } from "./mergeSandboxSpecForEnvVarsUpdate";
import { useApplyImage } from "./useApplyImage";
import CopyableText2 from "../../../../../components/CopyableText2";
import {EnvVar} from "./EnvVarEditor/Context";

type DeploymentDetailsProps = {
  sandbox: SandboxV2;
  workload: Workload;
};

const getBaselineEnvVars = (workload: Workload) =>
  workload.baseline?.spec.template.spec.containers[0].env ?? [];

const getImage = (fork: SandboxFork) => {
  const images = fork?.customizations?.images;
  if (!images || images.length === 0) return undefined;

  const forkImage = images[0];
  return forkImage?.image;
};

const getForkData = (
  sandbox: SandboxV2,
  targetFork: Omit<ForkOf, "kind">
): {
  envs: EnvVar[];
  image: undefined | string;
} => {
  const fork = sandbox.spec.forks?.find(
    (f) =>
      f.forkOf.name === targetFork.name &&
      f.forkOf.namespace === targetFork.namespace
  );

  if (!fork)
    return {
      image: undefined,
      envs: [],
    };

  const envs = fork.customizations.env ?? [];
  return {
    envs: forkCustomizationEnvToEnvVar(envs),
    image: getImage(fork),
  };
};

const DeploymentDetails = ({
  sandbox,
  workload: deploymentInfo,
}: DeploymentDetailsProps) => {
  const baselineName = deploymentInfo.baseline?.metadata.name ?? "";
  const baselineImage =
    deploymentInfo.baseline?.spec?.template?.spec?.containers[0]?.image ?? "";
  const baselineNamespace = deploymentInfo.baseline?.metadata.namespace;
  const forkedFrom = `${baselineNamespace}/${baselineName}`;

  const forkName = deploymentInfo.fork?.metadata.name ?? "";
  const forkNamespace = deploymentInfo.fork?.metadata.namespace ?? "";

  const [isEditingImage, setIsEditingImage] = useState(false);
  const [imageValue, setImageValue] = useState("");

  const updateSandbox = useSaveSandboxSpec(false);
  const updateSandboxImage = useApplyImage({
    sandbox,
    baselineName,
    forkNamespace,
    image: imageValue,
  });

  const baselineEnvVars = useMemo(
    () => getBaselineEnvVars(deploymentInfo),
    [deploymentInfo]
  );

  const forkData = useMemo(
    () =>
      getForkData(sandbox, {
        name: baselineName,
        namespace: forkNamespace,
      }),
    [sandbox]
  );

  const getForkImage = () => forkData.image ?? baselineImage;

  useEffect(() => {
    setImageValue(getForkImage());
  }, [forkData]);

  const handleApplySandbox = (updatedSpec: string) => {
    updateSandbox.trigger(updatedSpec);
  };

  const handleApplyImage = () => {
    if (isEditingImage) {
      updateSandboxImage.apply(handleApplySandbox);
    }
    setIsEditingImage((prev) => !prev);
  };
  const handleCancelImageEditing = () => {
    setIsEditingImage(false);
    setImageValue(getForkImage());
  };

  return (
    <div>
      <Card className="m-2" elevation={Elevation.TWO}>
        <H5>
          <a href="#runtime-info">Runtime Information</a>
        </H5>
        <Spacer />

        <div className={styles.details}>
          <span className={styles.header}>Baseline</span>
          <span className={styles.description}>
            Deployment <b>{forkedFrom}</b> in namespace <b>{forkNamespace}</b>.
          </span>
          <span className={styles.header}>Baseline image</span>
          <CopyableText2>{baselineImage}</CopyableText2>
          <span className={styles.header}>Sandbox</span>
          <span className={styles.description}>
            Deployment <b>{forkName}</b> in namespace <b>{forkNamespace}</b>.
          </span>
        </div>
      </Card>

      <div className={styles.image_container}>
        <p>Image</p>
        <InputGroup
          id="name"
          aria-label="enter-cluster-name"
          placeholder="Replacement Image (org/imagerepo:tag)"
          className={styles.image_input}
          disabled={!isEditingImage}
          value={imageValue}
          onChange={(e) => setImageValue(e.target.value)}
        />
        <SdButton icon="edit" onClick={handleApplyImage}>
          {!isEditingImage ? "Edit" : "Apply"}
        </SdButton>
        {isEditingImage && (
          <SdButton icon="cross" danger onClick={handleCancelImageEditing}>
            Cancel
          </SdButton>
        )}
      </div>

      <div>
        <p className={styles.section_title}>Env vars</p>
        <EnvVarEditor
          baselineEnvVars={baselineEnvVars}
          forkEnvVars={forkData.envs}
          baselineName={baselineName}
          forkNamespace={forkNamespace}
          onApplyChanges={handleApplySandbox}
          sandbox={sandbox}
        />
      </div>
    </div>
  );
};

export default DeploymentDetails;
