import type {
  FindingDiffOp,
  TrafficDiffFinding,
} from "../../../@types/sd/findings";
// @ts-ignore
// eslint-disable-next-line import/extensions
import type { DiffCat } from "../../../@types/sd/findings.d.ts";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { FindingKind } from "../../../@types/sd/findings.d.ts";
import type { ControlProps } from "./common/Controls/Controls";

export const getPrimaryDiff = (
  finding: TrafficDiffFinding
): FindingDiffOp | null => {
  const primaryDiffs = finding.diffOps.filter((x) => x.isPrimary);
  if (primaryDiffs.length === 0) {
    return null;
  }
  return primaryDiffs[0];
};

export const isPrimaryQueryParametersKind = (
  primaryDiffOp: FindingDiffOp
): boolean => primaryDiffOp.path.startsWith("/request/query");

export const isQueryParametersKind = (finding: TrafficDiffFinding): boolean => {
  const primaryDiffOp = getPrimaryDiff(finding);
  if (primaryDiffOp === null) {
    return false;
  }

  return isPrimaryQueryParametersKind(primaryDiffOp);
};

export const isRequestMetadataKind = (
  primaryDiffOp: FindingDiffOp
): boolean => {
  switch (primaryDiffOp.path) {
    case "/request/proto":
    case "/request/method":
    case "/request/uri":
    case "/request/host":
    case "/request/message/startedAt":
    case "/request/message/finishedAt":
    case "/request/query":
      return true;
    default:
      return false;
  }
};

export const isResponseMetadataKind = (
  primaryDiffOp: FindingDiffOp
): boolean => {
  switch (primaryDiffOp.path) {
    case "/response/error":
    case "/response/statusCode":
    case "/response/message/startedAt":
    case "/response/message/finishedAt":
      return true;
    default:
      return false;
  }
};

export const isMetadataKind = (finding: TrafficDiffFinding): boolean => {
  const primaryDiffOp = getPrimaryDiff(finding);
  if (primaryDiffOp === null) {
    return false;
  }

  return (
    isRequestMetadataKind(primaryDiffOp) ||
    isResponseMetadataKind(primaryDiffOp)
  );
};

export const isRequestHeaderKind = (primaryDiffOp: FindingDiffOp): boolean =>
  primaryDiffOp.path.startsWith("/request/message/headers");

export const isResponseHeaderKind = (primaryDiffOp: FindingDiffOp): boolean =>
  primaryDiffOp.path.startsWith("/response/message/headers");

export const isHeaderKind = (finding: TrafficDiffFinding): boolean => {
  const primaryDiffOp = getPrimaryDiff(finding);
  if (primaryDiffOp === null) {
    return false;
  }
  return (
    isRequestHeaderKind(primaryDiffOp) || isResponseHeaderKind(primaryDiffOp)
  );
};

export const isRequestBodyKind = (primaryDiffOp: FindingDiffOp): boolean =>
  primaryDiffOp.path.startsWith("/request/message/body") ||
  primaryDiffOp.path.startsWith("/request/message/[body]");

export const isResponseBodyKind = (primaryDiffOp: FindingDiffOp): boolean =>
  primaryDiffOp.path.startsWith("/response/message/body") ||
  primaryDiffOp.path.startsWith("/response/message/[body]");

export const isBodyKind = (finding: TrafficDiffFinding): boolean => {
  const primaryDiffOp = getPrimaryDiff(finding);
  if (primaryDiffOp === null) {
    return false;
  }
  return isRequestBodyKind(primaryDiffOp) || isResponseBodyKind(primaryDiffOp);
};

const identifyFindingKind = (
  finding: TrafficDiffFinding
): FindingKind | null => {
  if (isMetadataKind(finding)) {
    return FindingKind.Metadata;
  }
  if (isHeaderKind(finding)) {
    return FindingKind.Header;
  }
  if (isBodyKind(finding)) {
    return FindingKind.Body;
  }
  if (isQueryParametersKind(finding)) {
    return FindingKind.Query;
  }

  // we have categorized all fields in the capture as one of metadata, header or body.
  // So as of today, this should not happen.
  return null;
};

export const filterByRelevanceCategory = (
  finding: TrafficDiffFinding,
  filterCategories: DiffCat[]
): boolean => filterCategories.includes(finding.category);

export const filterFindings = (
  findings: TrafficDiffFinding[],
  controlProps: ControlProps
): TrafficDiffFinding[] =>
  findings.filter((finding) => {
    // return false if the finding doesn't match the category criteria
    if (!filterByRelevanceCategory(finding, controlProps.filterByCategories)) {
      return false;
    }

    // identify finding kind: metadata, header or body
    const kind = identifyFindingKind(finding);
    if (kind === null) {
      return false;
    }

    // if the kind is body, include it in the result
    if (kind === FindingKind.Body) {
      return true;
    }

    if (kind === FindingKind.Query) {
      return true;
    }

    // if the kind is metadata, include it in the result only if the metadata control is enabled
    if (kind === FindingKind.Metadata) {
      return controlProps.showMetadataFindings;
    }

    // if the kind is header, include it in the result only if the header control is enabled
    if (kind === FindingKind.Header) {
      return controlProps.showHeaderFindings;
    }

    // else, return false
    return false;
  });
