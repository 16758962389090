import React from "react";
import type { LocalWorkloadWithStatus } from "../../../../../@types/sd/sandboxv2";
import style from "../../Tests/ExecutionTitle/ExecutionTitle.module.css";
import type { Workload } from "../../../../../@types/sd/workload";

type WorkloadTitleProps =
  | {
      isLocal: false;
      workload: Workload;
    }
  | {
      isLocal: true;
      workload: LocalWorkloadWithStatus;
    };

export const getLocalTitle = (workload: LocalWorkloadWithStatus) => {
  const { localWorkload } = workload;

  return (
    <>
      {localWorkload.from.namespace}/{localWorkload.from.name}
    </>
  );
};

export const getDeploymentTitle = (workload: Workload) =>
  `${workload.fork!.metadata.namespace}/${workload.baseline?.metadata.name}`;

const WorkloadTitle = ({ isLocal, workload }: WorkloadTitleProps) => {
  const title = isLocal
    ? getLocalTitle(workload)
    : getDeploymentTitle(workload);

  return (
    <div className={style.container}>
      <p>{title}</p>
    </div>
  );
};

export default WorkloadTitle;
