import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import copy from "copy-to-clipboard";
import { Popover2 } from "@blueprintjs/popover2";
import styles from "./CopyableText2.module.css";

type CopyableText2Props = {
  bashScriptMode?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

const CopyableText2 = ({
  bashScriptMode = false,
  children,
}: CopyableText2Props) => {
  const [isClicked, setClicked] = useState(false);
  useEffect(() => {
    if (isClicked) {
      setTimeout(() => setClicked(false), 1000);
    }
  }, [isClicked]);
  const childrenRef = useRef<HTMLElement>(null);

  const getText = () => {
    const { current } = childrenRef;
    if (!current) {
      return "";
    }
    if (bashScriptMode) {
      // Allow the children to be written "naturally" by adding br elements to create
      // newlines in the display, but still generate a valid multi-line bash command.
      return current.innerText.replace(/\n/, " && \\\n");
    }
    return current.innerText;
  };

  const onCopyClick = () => {
    copy(getText());
    setClicked(true);
  };

  return (
    <div className={styles.copyable}>
      <span ref={childrenRef}>{children}</span>
      <Popover2
        isOpen={isClicked}
        content={<span className="m-2">Copied!</span>}
        placement="top"
      >
        <Icon
          icon="duplicate"
          className="m-0 p-0 cursor-pointer"
          onClick={onCopyClick}
          aria-label="copy-text-button"
        />
      </Popover2>
    </div>
  );
};

export default CopyableText2;
