import type {
  QueryResult,
  TestExecution,
} from "../../../../@types/sd/testexecutions";

// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatus } from "../../../../@types/sd/testexecutions.d.ts";

// eslint-disable-next-line import/prefer-default-export
export const groupByTest = (
  executions: QueryResult[]
): Record<string, TestExecution> => {
  const grouped = {};

  executions.forEach((e) => {
    const execution = e.execution;
    const testName = execution.spec.test;
    if (!grouped[testName]) {
      grouped[testName] = execution;
    }
  });

  return grouped;
};

type groupByTestCompletionReturn = {
  completed: TestExecution[];
  others: TestExecution[];
};

// eslint-disable-next-line import/prefer-default-export
export const groupByTestCompletion = (
  executions: TestExecution[]
): groupByTestCompletionReturn => {
  const groupedExecutions: groupByTestCompletionReturn = {
    completed: [],
    others: [],
  };

  executions.forEach((execution) => {
    switch (execution.status.phase) {
      case TestExecutionStatus.Succeeded:
        groupedExecutions.completed.push(execution);
        break;
      case TestExecutionStatus.Pending:
      case TestExecutionStatus.InProgress:
      case TestExecutionStatus.Failed:
      case TestExecutionStatus.Canceled:
      default:
        groupedExecutions.others.push(execution);
    }
  });

  return groupedExecutions;
};