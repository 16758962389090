import React from "react";
import { Card, Elevation, H5 } from "@blueprintjs/core";
import Spacer from "../../../../../components/Util/Util";
import type { LocalWorkloadWithStatus } from "../../../../../@types/sd/sandboxv2";
import styles from "./LocalDetails.module.css";
import { getLocalConnectedPath } from "../utils";

type LocalDetailsProps = {
  workload: LocalWorkloadWithStatus;
};

const LocalDetails = ({
  workload: localWorkloadWithStatus,
}: LocalDetailsProps) => {
  const localConnected = getLocalConnectedPath(localWorkloadWithStatus);
  const { localWorkload } = localWorkloadWithStatus;

  return (
    <div>
      <Card className="m-2" elevation={Elevation.TWO}>
        <H5>
          <a href="#">Runtime Information</a>
        </H5>
        <Spacer />

        <div className={styles.details}>
          <span className={styles.header}>Baseline</span>
          <span>
            Deployment <b>{localWorkload.from.name}</b> in namespace
            <b>{localWorkload.from.namespace}</b>
          </span>
          {localConnected ? (
            <>
              <span className={styles.header}>Sandbox</span>
              <span>
                Connected to <b>{localConnected}</b>
              </span>
            </>
          ) : (
            <span>No local workstation connected</span>
          )}
        </div>
      </Card>
    </div>
  );
};

export default LocalDetails;
