import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { useSandboxByNameApi } from "../../api/SandboxesApi";
import style from "./SandboxDetails.module.css";
import PlaygroundSandboxBanner from "./PlaygroundSandboxBanner";
import PRInfo from "./PRInfo";
import DeleteSandboxAlert from "../Sandboxes/components/DeleteSandboxAlert";
import SandboxOverview from "./Tabs/SandboxOverview";
import SandboxSpec from "./Tabs/SandboxSpec";
import {
  SandboxDetailTabExplore,
  SandboxDetailTabLogs,
  SandboxDetailTabOverview,
  SandboxDetailTabSpecification,
  SandboxDetailTabTests,
  SandboxDetailTabWorkloads,
} from "../../Constants";
import Explore from "../Explore";
import Badge from "../../components/theming/Badge";
import SandboxLogs from "./Tabs/SandboxLogs";
import MultiTabsLayout from "../../components/MultiTabsLayout/MultiTabsLayout";
import Tests from "./Tabs/Tests";
import { useQuerySandboxTestExecutions } from "../../api/TestExecutionsApi";
import type { TabProps } from "../../components/theming/Tabs/Tabs";
import { getSandboxStatus } from "./SandboxStatus/SandboxStatus";
import Workloads from "./Tabs/Workloads";
import useFeatureFlag from "../../hooks/UseFeatureFlag";

const SandboxDetails: React.FunctionComponent = function () {

  const navigate = useNavigate();
  // If we get a 400 error from the backend, prevent ourselves from constantly trying to
  // fetch data and turning the error page into a spinner load page. If we can't find
  // the formattedSandboxData because it doesn't exist, we shouldn't keep trying.
  const [queryEnabled, setQueryEnabled] = useState(true);
  const { sandboxName } = useParams<{ sandboxName: string }>();
  // TODO: Use error boundaries to immediately detect a 404 for disabling the query.
  // Requires an update to have the formattedSandboxData endpoint return a 404.
  const {
    isLoading,
    isIdle,
    data: sandbox,
    isSuccess,
    error,
  } = useSandboxByNameApi(sandboxName!, {
    enabled: queryEnabled,
  });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  useEffect(() => {
    const status = _.get(error, ["response", "status"]);
    if (status === 400) {
      setQueryEnabled(false);
    }
  }, [error]);

  const sandboxTestExecutions = useQuerySandboxTestExecutions(
    sandbox?.name ?? "",
    {
      enabled: !!sandbox?.name,
    }
  );

  if (isLoading || isIdle) {
    return <Loading />;
  }

  if (error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (error.response?.status === 400) {
      // Response isn't set for timeouts, DNS resolution failures, server crash etc. Hence, the optional check above.
      navigate("/sandboxes");
      return null;
    }
    return (
      <Error
        text={`Error: ${
          error.message || "Unknown error fetching the formattedSandboxData"
        }`}
      />
    );
  }

  if (!isSuccess || !sandbox) {
    return null;
  }

  const handleDeleteSandbox = () => {
    setDeleteModalOpen(true);
  };

  const tabs: TabProps[] = [
    {
      label: "Overview",
      slug: "overview",
      eventName: SandboxDetailTabOverview,
      content: <SandboxOverview sandbox={sandbox} />,
    },
    {
      label: "Specification",
      slug: "specification",
      eventName: SandboxDetailTabSpecification,
      content: <SandboxSpec sandbox={sandbox} />,
    },
    {
      label: "Workloads",
      slug: "workloads",
      eventName: SandboxDetailTabWorkloads,
      content: <Workloads sandbox={sandbox} />,
    },
    {
      label: (
        <div className={style.newTag}>
          <div>Smart Tests</div>
          <Badge label={sandboxTestExecutions.data?.length.toString() ?? "-"} />
        </div>
      ),
      slug: "tests",
      eventName: SandboxDetailTabTests,
      content: <Tests sandbox={sandbox} />,
    },
    {
      label: "Logs",
      slug: "logs",
      eventName: SandboxDetailTabLogs,
      content: <SandboxLogs sandbox={sandbox} />,
    },
    {
      label: "Explore",
      slug: "explore",
      eventName: SandboxDetailTabExplore,
      content: <Explore sandbox={sandbox} />,
    },
  ];

  return (
    <div>
      {/* Modal Dialog to confirm deletion */}
      <DeleteSandboxAlert
        name={sandbox.name}
        isDeleteModalOpen={isDeleteModalOpen}
        onCancel={() => {
          setDeleteModalOpen(false);
        }}
        onComplete={() => {
          setDeleteModalOpen(false);
        }}
      />
      <MultiTabsLayout
        tabs={tabs}
        title={sandbox.name}
        actions={[
          {
            label: "Delete",
            onClick: handleDeleteSandbox,
            display: true,
            isRiskAction: true,
            icon: "trash",
          },
        ]}
        breadcrumbs={[
          {
            text: "Sandboxes",
            onClick: () => navigate("/sandboxes"),
          },
          { text: sandbox.name },
        ]}
        status={getSandboxStatus(sandbox.status)}
        headingNode={<PRInfo spec={sandbox?.spec} />}
      >
        {
          // TODO: Check for playground by name isn't reliable as we may choose to use a different name in future. Address this later.
          sandbox?.spec.cluster === "playground-cluster" ? (
            <PlaygroundSandboxBanner />
          ) : null
        }
      </MultiTabsLayout>
    </div>
  );
};

export default SandboxDetails;
