import type { Icon } from "@blueprintjs/core";
import React from "react";
import { TbAlertOctagon } from "react-icons/tb";
import classNames from "classnames";
import { RiAlertLine } from "react-icons/ri";
import { IoInformationCircleOutline } from "react-icons/io5";
import pluralize from "pluralize";
import { RxCrossCircled } from "react-icons/rx";
import { CiCircleCheck } from "react-icons/ci";
import type { CheckResults } from "../../../../../@types/sd/testexecutions";
import styles from "./TestCollapseTitle.module.css";
import type CategoryFilter from "../Controls/CategoryFilter";
import type { TestCollapseTitleProps } from "./TestCollapseTitle";

export type TestCollapseTitleDiffProps = {
  testType: "diff";
  findingsCount: React.ComponentProps<typeof CategoryFilter>["findingsCount"];
};

export type TestCollapseTitleChecksProps = {
  testType: "checks";
  checksResult: CheckResults;
};

export type TestCollapseWithTestType =
  | TestCollapseTitleDiffProps
  | TestCollapseTitleChecksProps;

type GetRenderingDataReturn = {
  icon: {
    icon: React.ComponentProps<typeof Icon>["icon"];
    className?: string;
  };
  description: string;
};

export const getExtraDetailsForDiffRendering = (
  data: TestCollapseTitleDiffProps,
  iconSize = 16
): GetRenderingDataReturn => {
  const defaultRendering: GetRenderingDataReturn = {
    icon: {
      icon: "ban-circle",
      className: styles.canceled,
    },
    description: "no diff found",
  };

  const { high, medium, low } = data.findingsCount;

  let auxMedium = "";
  if (medium > 0) {
    auxMedium = `, ${medium} medium`;
  }

  const DETAILS_MAP: Array<{ isValid: boolean } & GetRenderingDataReturn> = [
    {
      isValid: high > 0,
      icon: {
        icon: (
          <TbAlertOctagon
            className={classNames(styles.failed)}
            size={iconSize}
          />
        ),
      },
      description: `${high} high${auxMedium} relevance ${pluralize(
        "diff",
        high
      )}`,
    },
    {
      isValid: medium > 0,
      icon: {
        icon: (
          <RiAlertLine className={classNames(styles.yellow)} size={iconSize} />
        ),
      },
      description: `${medium} medium relevance ${pluralize("diff", medium)}`,
    },
    {
      isValid: low > 0,
      icon: {
        icon: (
          <IoInformationCircleOutline
            className={classNames(styles.succeeded)}
            size={iconSize}
          />
        ),
      },
      description: `${low} low relevance ${pluralize("diff", low)}`,
    },
  ];

  const selectedDetail = DETAILS_MAP.find((i) => i.isValid);

  return {
    ...(selectedDetail ?? defaultRendering),
  };
};

export const getExtraDetailsForChecksRendering = (
  data: TestCollapseTitleChecksProps
): GetRenderingDataReturn => {
  const defaultRendering: GetRenderingDataReturn = {
    icon: {
      icon: "ban-circle",
      className: styles.canceled,
    },
    description: "no checks found",
  };

  const sandbox = data.checksResult.sandbox;
  if (!sandbox) return defaultRendering;

  const totalErrors = sandbox.reduce((previousValue, currentValue) => {
    if (currentValue.errors && currentValue.errors.length > 0)
      return previousValue + 1;

    return previousValue;
  }, 0);

  const description = `${sandbox.length - totalErrors}/${
    sandbox.length
  } ${pluralize("check", sandbox.length - totalErrors)} passed`;

  const DETAILS_MAP: Array<{ isValid: boolean } & GetRenderingDataReturn> = [
    {
      isValid: totalErrors > 0,
      icon: {
        icon: <RxCrossCircled className={styles.failed} size={16} />,
      },
      description: description,
    },
    {
      isValid: totalErrors === 0,
      icon: {
        icon: <CiCircleCheck className={styles.succeeded} size={16} />,
        className: styles.succeeded,
      },
      description: description,
    },
  ];

  const selectedDetail = DETAILS_MAP.find((i) => i.isValid);

  return {
    ...(selectedDetail ?? defaultRendering),
  };
};

export const getExtraDetailsForRendering = (
  opts: TestCollapseTitleProps
): GetRenderingDataReturn => {
  if (opts.testType === "diff") {
    return getExtraDetailsForDiffRendering(opts);
  }

  return getExtraDetailsForChecksRendering(opts);
};
