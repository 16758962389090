import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon, Intent } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import MultiTabsLayout from "../../../components/MultiTabsLayout/MultiTabsLayout";
import Error from "../../../components/Error/Error";
import DetailItem from "../../../components/DetailItem";
import Loading from "../../../components/Loading/Loading";
import StatusCard from "../../../components/ResourceStatus/StatusCard";
import type { TestExecution } from "../../../@types/sd/testexecutions";
import getExecutionStatus from "./common/ExecutionStatus/ExecutionStatus";
import { TestDiff } from "./TestDiff";
import { TestCheck } from "./TestCheck";
import useGetTestExecutionDetails from "./useGetTestExecutionDetails";

interface TestExecutionDetailProps {
  testName?: string;
  executionName?: string;
  hideBreadcrumbs?: boolean;
  onNotFound?: () => void;
  customTitle?: (testDetailName: string) => React.ReactNode;
}

const TestExecutionDetail: React.FC<TestExecutionDetailProps> = ({
  testName: testNameProp,
  executionName: executionNameProp,
  customTitle = (t) => t,
  hideBreadcrumbs = false,
  onNotFound,
}) => {
  const {
    testName: testNameFromParams,
    executionName: executionNameFromParams,
  } = useParams<{
    testName: string;
    executionName: string;
  }>();

  const testName = testNameProp || testNameFromParams;
  const executionName = executionNameProp || executionNameFromParams;

  const testExecutionDetails = useGetTestExecutionDetails({
    testName,
    executionName,
  });

  const {
    raw: { executionDetail },
    tests: {
      diff: { controlProps, setControlProps, filteredFindings },
    },
  } = testExecutionDetails;

  const navigate = useNavigate();

  const errorMessage = React.useMemo(() => {
    if (!executionDetail.data) return null;

    const status = executionDetail.data.status;
    if (status.phase !== "failed") {
      return null;
    }
    if (!status.finalState?.failed) {
      return null;
    }

    return status.finalState.failed.message;
  }, [executionDetail]);

  if (executionDetail.error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (executionDetail.error.response?.status === 400) {
      // Response isn't set for timeouts, DNS resolution failures, server crash etc. Hence, the optional check above.
      if (onNotFound) {
        onNotFound();
      } else {
        navigate("/testing/tests");
      }
      return null;
    }
    return (
      <Error
        text={`Error: ${
          executionDetail.error.message || "Unknown error fetching the test"
        }`}
      />
    );
  }

  if (executionDetail.isLoading || !executionDetail.data) {
    return <Loading />;
  }

  const breadCrumbs = [
    {
      text: "Smart Tests",
      onClick: () => navigate("/testing/tests"),
    },
    {
      text: `Smart Test: ${testName}`,
      onClick: () => navigate(`/testing/tests/${testName}`),
    },
    {
      text: `Execution: ${executionName}`,
    },
  ];

  if (!executionDetail.data) {
    return null;
  }

  const executionData = executionDetail.data as TestExecution;
  const triggeredBy = executionData.status.triggeredBy;

  return (
    <MultiTabsLayout
      tabs={[]}
      breadcrumbs={hideBreadcrumbs ? undefined : breadCrumbs}
      title={customTitle(executionDetail.data?.name || "")}
      status={getExecutionStatus(executionData.status.phase)}
    >
      {errorMessage && (
        <div className="mb-5">
          <StatusCard
            title="Job Failed"
            description={errorMessage}
            intent={Intent.DANGER}
          />
        </div>
      )}
      <div className="mb-1">
        <DetailItem
          title="Test"
          value={
            executionData.status.testDeletedAt ? (
              <Tooltip2 content="The test has been deleted">
                <>
                  {executionDetail.data.name}{" "}
                  <Icon icon="warning-sign" size={15} intent={Intent.WARNING} />
                </>
              </Tooltip2>
            ) : (
              <Link to={`/testing/tests/${executionData.spec.test}/`}>
                {executionData.spec.test}
              </Link>
            )
          }
        />
      </div>
      {triggeredBy && (
        <div className="mb-2">
          <b>Triggered on</b>{" "}
          <Link to={`/sandbox/name/${triggeredBy.sandbox}/`}>
            {triggeredBy.sandbox}
          </Link>{" "}
          containing fork of {triggeredBy.trigger.sandboxOf.namespace}/
          {triggeredBy.trigger.sandboxOf.name}
        </div>
      )}
      <div className="mb-4">
        <span className="font-bold mb-0">Jobs</span>
        <ul className="mt-0 py-0">
          <li>
            <Link
              to={`/testing/jobs/${executionDetail.data.status.baselineJob}`}
            >
              {executionDetail.data.status.baselineJob}
            </Link>{" "}
            (baseline)
          </li>
          <li>
            <Link to={`/testing/jobs/${executionDetail.data.status.targetJob}`}>
              {executionDetail.data.status.targetJob}
            </Link>{" "}
            (sandbox)
          </li>
        </ul>
      </div>

      <TestDiff
        controlProps={controlProps}
        onControlPropChange={setControlProps}
        executionName={executionName}
        testName={testName}
        executionStatus={executionDetail.data.status.phase}
        executionDetails={executionDetail.data}
        filteredFindings={filteredFindings}
      />
      <TestCheck
        executionName={executionName}
        testName={testName}
        executionStatus={executionDetail.data.status.phase}
        checkResults={executionDetail.data?.results?.checks}
        executionDetails={executionDetail.data}
      />
    </MultiTabsLayout>
  );
};

export default TestExecutionDetail;
