import React, { useCallback } from "react";
import { Icon } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";
import { RxCrossCircled } from "react-icons/rx";
import { CiCircleCheck } from "react-icons/ci";
import type {
  CheckResultItem,
  CheckResults,
} from "../../../@types/sd/testexecutions";
import Collapse from "./common/Collapse";
import { TestCollapseTitle } from "./common/TestCollapseTitle/TestCollapseTitle";
import styles from "./TestCheck.module.css";
import type { TestTypeView } from "./common";
import type { CollapseOptionsFunc } from "./common/Collapse/Collapse";

export type TestCheckProps = {
  checkResults?: CheckResults;
} & TestTypeView;

type CombinedCheckResult = {
  sandbox?: CheckResultItem;
  baseline?: CheckResultItem;
  name: string;
  id: string;
};

/**
 * combineCheckResults combine the checkNames from sandbox and baseline
 * but keeping a reference to both in case exist. These helps in the
 * calculation for rendering filteredView but also to determine whether
 * a check was skipped or not */
const combineCheckResults = (
  checkResults: CheckResults
): CombinedCheckResult[] => {
  const results = new Map<string, CombinedCheckResult>();

  const { sandbox, baseline } = checkResults;

  if (sandbox) {
    sandbox.forEach((checkItem) => {
      const { name } = checkItem;

      results.set(name, {
        sandbox: checkItem,
        name: name,
        id: name,
      });
    });
  }

  if (baseline) {
    baseline.forEach((checkItem) => {
      const { name } = checkItem;

      const existingCombinedItem = results.get(name);
      if (existingCombinedItem) {
        results.set(name, {
          ...existingCombinedItem,
          baseline: checkItem,
        });
        return;
      }

      results.set(name, {
        baseline: checkItem,
        name: name,
        id: name,
      });
    });
  }

  return Array.from(results.values());
};

const getIconStyle = (checkResultItem: CheckResultItem | undefined) => {
  if (!checkResultItem) {
    return {
      hasErrors: false,
      icon: <RxCrossCircled className={styles.failed} size={20} />,
    };
  }

  const hasErrors = checkResultItem.errors && checkResultItem.errors.length > 0;

  return {
    hasErrors,
    icon: hasErrors ? (
      <RxCrossCircled className={styles.failed} size={20} />
    ) : (
      <CiCircleCheck className={styles.succeeded} size={20} />
    ),
    className: hasErrors ? styles.failed : styles.succeeded,
  };
};

const checkContainsErrors = (checkResult: CombinedCheckResult) => {
  if (checkResult.sandbox?.errors && checkResult.sandbox.errors.length > 0)
    return true;

  return !!(
    checkResult.baseline?.errors && checkResult.baseline.errors.length > 0
  );
};

type CheckReportCardProps = {
  checkResult: CombinedCheckResult;
};

const CheckReportCard = ({ checkResult }: CheckReportCardProps) => {
  const hasErrors = checkContainsErrors(checkResult);
  if (!hasErrors) return null;

  const sandbox = getIconStyle(checkResult.sandbox);
  const baseline = getIconStyle(checkResult.baseline);

  return (
    <div className={styles.report_details}>
      <div className={styles.report_card}>
        <div className={styles.card_header}>
          <span>Status (Sandbox) </span>
          <span className={sandbox.className}>
            {sandbox.hasErrors ? "FAIL" : "PASS"}
          </span>
        </div>
        <div className={styles.body_errors}>
          <b>{sandbox.hasErrors ? "Errors" : "No errors"}</b>
          {checkResult.sandbox?.errors?.map((error) => (
            <div className={styles.error_display}>{error.message}</div>
          ))}
        </div>
      </div>
      <div className={styles.report_card}>
        <div className={styles.card_header}>
          <span>Status (Baseline)</span>
          <span className={baseline.className}>
            {baseline.hasErrors ? "FAIL" : "PASS"}
          </span>
        </div>
        <div className={styles.body_errors}>
          <b>{baseline.hasErrors ? "Errors" : "No errors"}</b>
          {checkResult.baseline?.errors?.map((error) => (
            <div className={styles.error_display}>{error.message}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const TestCheck = ({
  checkResults,
  testName,
  executionName,
  executionStatus,
}: TestCheckProps) => {
  if (!testName || !executionName || !checkResults) return null;

  const combinedResults = combineCheckResults(checkResults);

  if (combinedResults.length === 0) return null;

  const CollapseTitle = useCallback(
    (collapseFuncOpts: CollapseOptionsFunc) => (
      <TestCollapseTitle
        testName={testName as string}
        executionStatus={executionStatus}
        testType="checks"
        checksResult={checkResults}
        collapseFuncOpts={collapseFuncOpts}
      />
    ),
    [testName, executionStatus, checkResults]
  );

  const getResultTitle = (
    checkResult: CombinedCheckResult,
    opts: CollapseOptionsFunc
  ) => {
    const sandbox = getIconStyle(checkResult.sandbox);
    const baseline = getIconStyle(checkResult.baseline);

    const showCollapse = sandbox.hasErrors || baseline.hasErrors;

    return (
      <div className={styles.collapse_results_title}>
        <b>{checkResult.name}</b>
        <div className="flex items-center">
          <Icon icon={sandbox.icon} className={sandbox.className} size={16} />
          <span className={sandbox.className}>
            {sandbox.hasErrors ? "FAIL" : "PASS"}
          </span>
          <Collapse.CollapseButton
            isExpanded={opts.isExpanded}
            onToggle={opts.onToggle}
            className={!showCollapse ? styles.hide_collapse : undefined}
          />
        </div>
      </div>
    );
  };

  return (
    <Collapse
      show
      title={CollapseTitle}
      bodyStyle={styles.container}
      headerStyle={styles.header}
      defaultOpen={false}
    >
      <div>
        {combinedResults.map((checkResult) => (
          <Collapse
            title={(collapseOpts) => getResultTitle(checkResult, collapseOpts)}
            show
            containerStyle={styles.collapse_results_container}
            defaultOpen={false}
            key={checkResult.name}
          >
            <CheckReportCard checkResult={checkResult} />
          </Collapse>
        ))}
      </div>
    </Collapse>
  );
};
