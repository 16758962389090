interface ContainerStatus {
  state: {
    waiting: {
      reason: string;
    };
  };
}

interface PodStatus {
  conditions: {
    type: string;
    status: string;
    reason: string;
  }[];
  containerStatuses: ContainerStatus[];
  phase: string;
}

const findContainerReason = (cStatuses?: ContainerStatus[]) => {
  if (!Array.isArray(cStatuses)) {
    return "none";
  }
  for (let i = 0; i < cStatuses.length; i++) {
    const { state } = cStatuses[i];
    if (state.waiting) {
      return state.waiting.reason;
    }
  }
  return "none";
};

// eslint-disable-next-line import/prefer-default-export
export const computeReason = (podStatus: PodStatus) => {
  if (!podStatus) {
    return "none";
  }
  const conds = podStatus.conditions || [];
  for (let i = 0; i < conds.length; i++) {
    const cond = conds[i];
    if (cond.type === "Ready") {
      if (cond.status === "True") {
        return "ContainersReady";
      }
      if (cond.reason === "ContainersNotReady") {
        return findContainerReason(podStatus.containerStatuses);
      }
    } else if (cond.status !== "True") {
      // cannot be ready in this case
      return cond.reason;
    }
  }
  return "none";
};
