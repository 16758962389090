import * as React from "react";
import { useNavigate } from "react-router-dom";
import LabeledCard from "../../../components/theming/LabeledCard/LabeledCard";
import styles from "./ForkCard.module.css";
import Fork from "../Fork";
import type { FormattedDeployment } from "../Tabs/SandboxOverview/SandboxOverview";

interface ForkProps {
  // eslint-disable-next-line react/no-unused-prop-types
  sandboxName: string;
  deployment: FormattedDeployment;
}

const ForkCard: React.FunctionComponent<ForkProps> = ({
  deployment,
  sandboxName,
}) => {
  const navigate = useNavigate();
  const name = deployment.data.fork!.metadata.name;
  return (
    <LabeledCard
      label="fork"
      titleBarContent={<div>{deployment.status}</div>}
      buttonInfos={[
        {
          id: "view-detail",
          icon: "list-detail-view",
          label: "View Details",
          onClick: () => {
            navigate(`/sandbox/name/${sandboxName}/workloads/`);
          },
        },
        {
          id: "view-logs",
          icon: "console",
          label: "View Logs",
          onClick: () =>
            navigate(
              // `/sandbox/name/${sandboxName}/logs/?type=Fork&name=${name}`
              `/sandbox/name/${sandboxName}/logs?type=Fork&name=${name}`
            ),
        },
      ]}
    >
      <div className={styles.container}>
        <Fork
          parent={`${deployment.data.fork!.metadata.namespace}/${
            deployment.data.baseline?.metadata.name
          }`}
          child={name}
        />
      </div>
    </LabeledCard>
  );
};

export default ForkCard;
